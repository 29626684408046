import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout";
import SEO from '../components/seo'
import ContentHeaderRich from '../components/ContentHeaderRich';
import ContentBody from '../components/ContentBody';
import FAQSet from '../components/FAQSet.jsx'

export default ({ pageContext }) => {
  const { title, text, seomatic, faqSet, richText } = pageContext;

  return (
    <Layout>
        <SEO seomatic={seomatic} />
        <div className="mx-auto bg-grey-300 max-w-screen-xxl grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article mb-8 lg:mb-15">
            <div className="lg:col-start-2 lg:col-end-3">
                <ContentHeaderRich title={title} subtitle={text} richText={richText}/>
            </div>
        </div>
        <div className="mx-auto  max-w-screen-xxl grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article mb-8 lg:mb-15">
            <div className="lg:col-start-2 lg:col-end-3">
                {faqSet.length ? 
                <FAQSet faqSet={faqSet} />
                : 
                <ContentBody>
                    <div className="px-5 sm:px-0 my-5 lg:my-2">
                        <p className="break-words mb-6 lg:mb-10 leading-6">
                            Wie können wir Ihnen sonst helfen?
                        </p>
                        <ol>
                        <li>
                            <div className="break-words mb-6 lg:mb-15 leading-6">
                            Zu unseren häufig gestellten Fragen 
                            <Link to="/faq" aria-label="faq" className="button-primary ml-2 md:ml-4">
                                FAQs
                            </Link>
                            </div>
                        </li>
                        <li>
                            <div className="break-words mb-6 lg:mb-15 leading-6">
                            Zurück zur
                            <Link to="/" aria-label="Homepage" className="button-primary ml-2 md:ml-4">
                                Startseite
                            </Link>
                            </div>
                        </li>
                        </ol>
                        
                        <div className="break-words mb-6 lg:mb-15 leading-6">
                        Sie haben persönliche Fragen, dann nehmen Sie gerne direkt 
                        <Link to="/kontakt" aria-label="Kontakt" className="button-tertiary ml-2 mr-2">
                            Kontakt
                        </Link> 
                        mit uns auf.
                        </div>

                    </div>
                </ContentBody>
                }
            </div>
        </div>
    </Layout>
  );
}